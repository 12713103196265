<template></template>

<script>
import { getTransactionToken } from "../services";
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://app.sandbox.midtrans.com/snap/snap.js"
    );
    recaptchaScript.setAttribute(
      "data-client-key",
      "SB-Mid-client-YVoMnezBm_Av6CI2"
    );
    document.head.appendChild(recaptchaScript);
    this.pay();
  },
  methods: {
    async pay() {
      let transactionToken = {};
      getTransactionToken()
        .then((succes) => {
          console.log("transactionToken : ", succes.transactionToken);
          window.snap.pay(succes.transactionToken);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    },
  },
};
</script>
